import styled from 'styled-components'

const StyledMatrixAnimation = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
`
export default StyledMatrixAnimation
