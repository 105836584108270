import React from "react"
import StyledKeepInTouch from "./styles"
import KeepInTouchIcon from "./components/KeepInTouchIcon"
import { Icon } from "semantic-ui-react"

const KeepInTouch = ({ icons, theme }) => (
  <StyledKeepInTouch theme={theme}>
    <span>keep in touch</span>
    <Icon.Group>
      {icons.map((icon, i) => {
        return <KeepInTouchIcon {...icon} key={i} theme={theme} />
      })}
    </Icon.Group>
  </StyledKeepInTouch>
)

export default KeepInTouch
