import styled from "styled-components"

const StyledKeepInTouch = styled.div`
  .icons {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1rem !important;
  }
  span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.keepInTouchTextColor};
    font-family: ${({ theme }) => theme.keepInTouchFontFanily};
  }
`
export default StyledKeepInTouch
