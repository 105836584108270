import PropTypes from "prop-types"
import React from "react"
import StyledHeader from "./styles"
import { ThemeProvider } from "styled-components"
import { theme } from "../../styles"

const Header = ({ siteTitle, subTitle, siteShortTitle }) => (
  <ThemeProvider theme={theme}>
    <div width="100%">
      <StyledHeader as={"h1"}>{siteTitle}</StyledHeader>
      <StyledHeader as={"h1"} target="short">
        {siteShortTitle}
      </StyledHeader>
      <StyledHeader as={"h2"}>{subTitle}</StyledHeader>
    </div>
  </ThemeProvider>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  subTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
  subTitle: "",
}

export default Header
