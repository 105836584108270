import React from "react"
import { Icon } from "semantic-ui-react"
import StyledKeepInTouchIcon from "./styles"
import { Link } from "gatsby"

const KeepInTouchIcon = ({ url, name, theme, openInNewTab }) => (
  <StyledKeepInTouchIcon theme={theme}>
    {openInNewTab && (
      <a
        href={url}
        target={"_blank"}
        rel="noopener noreferrer"
      >
        <Icon link inverted circular name={name} size="big" />
      </a>
    )}
    {!openInNewTab && (
      <Link to={url}>
        <Icon link inverted circular name={name} size="big" />
      </Link>
    )}
  </StyledKeepInTouchIcon>
)

export default KeepInTouchIcon
