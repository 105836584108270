import React from "react"
import Layout from "src/components/Layout"
import SEO from "src/components/seo"
import Header from "src/components/Header"
import KeepInTouch from "src/components/KeepInTouch"
import MatrixAnimation from "src/components/MatrixAnimation"
import { GlobalStyle } from "src/styles"
import { ThemeProvider } from "styled-components"
import { theme } from "src/styles"
import { StaticQuery, graphql } from "gatsby"

import posed from "react-pose"

const CheckoutMyBlog = posed.a({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    color: theme.mainColor,
    backgroundColor: theme.bgColor,
    borderRadius: 3,
    fontStyle: "italic",
    fontSize: "1.3rem",
    padding: 9,
  },
  hover: {
    scale: 1.3,
    color: theme.bgColor,
    backgroundColor: theme.mainColor,
    padding: 9,
    fontSize: "1.31rem",
  },
  press: {
    scale: 1.4,
    color: theme.bgColor,
    backgroundColor: theme.mainColor,
    padding: 10,
    fontSize: "1.32rem",
  },
})

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            name
            title
            shortTitle
            subTitle
            blogLinkDescription
            icons {
              name
              url
              openInNewTab
            }
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Layout theme={theme}>
          <MatrixAnimation />
          <Header
            siteTitle={data.site.siteMetadata.title}
            siteShortTitle={data.site.siteMetadata.shortTitle}
            subTitle={data.site.siteMetadata.subTitle}
          />
          <div>
            <KeepInTouch icons={data.site.siteMetadata.icons} theme={theme} />
          </div>
          <div
            style={{
              color: theme.mainColor,
              marginTop: "3.5rem",
              fontSize: "1.2rem",
            }}
          >
            <CheckoutMyBlog href="https://blog.fethierdincuzun.com">
              { data.site.siteMetadata.blogLinkDescription }
            </CheckoutMyBlog>
          </div>

          <SEO
            title= {data.site.siteMetadata.name}
            keywords={["gatsby", "application", "react"]}
          />
          <GlobalStyle overflow="hidden" touchAction="none" />
        </Layout>
      </ThemeProvider>
    )}
  />
)

export default IndexPage
