import styled from 'styled-components'
import posed from 'react-pose'

const options = {
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  press: {
    scale: 1.1,
  },
}

const StyledKeepInTouchIcon = styled(posed.div(options))`
  i.inverted.circular.icon {
    color: ${({theme}) => theme.iconColor} !important;
  }
  i.inverted.circular.icon:hover {
    color: ${({theme}) => theme.iconBgColor} !important;
    background-color: ${({theme}) => theme.iconColor} !important;
  }
  margin: 0.11rem;
  .icon {
    opacity: 1 !important;
  }
  .inverted.circular.icon {
    background-color: ${({theme}) => theme.iconBgColor} !important;
  }
  .inverted.circular.icon {
    background-color: ${({theme}) => theme.iconBgColor} !important;
  }
`

export default StyledKeepInTouchIcon
