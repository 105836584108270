import React from "react"

import StyledMatrixAnimation from "./styles"

class MatrixAnimation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 400,
      height: 400,
      characters: "1110101010100101".split(""),
      fontSize: 14,
      columns: 0,
      drops: [],
      interval: 1000 / 30,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.width === nextState.width &&
      this.state.height === nextState.height
    )
      return false
    return true
  }
  getHeightAndWidth() {
    let height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    let width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    return { height, width }
  }

  getCalculatedFontSize() {
    let fontSize = parseInt(
      window.getComputedStyle(document.getElementsByTagName("html")[0])
        .fontSize || 14
    )
    if (fontSize < 10) {
      fontSize = 10
    }
    return fontSize
  }

  componentDidMount() {
    let { height, width } = this.getHeightAndWidth()

    let fontSize = this.getCalculatedFontSize()

    const columns = width / fontSize
    const drops = []

    for (let x = 0; x < columns; x++) drops[x] = 1

    this.setState({ height, width, columns, drops, fontSize }, () => {})

    window.onresize = () => {
      height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      let drops = []
      fontSize = this.getCalculatedFontSize()
      let columns = width / fontSize

      for (let x = 0; x < columns; x++) drops[x] = 1

      this.setState({ height, width, columns, drops, fontSize })
    }

    this.timerID = setInterval(() => {
      this.rAFID = window.requestAnimationFrame(t => this.runAnimation(t))
    }, 1000 / 22)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
    window.cancelAnimationFrame(this.rAFID)
  }
  runAnimation() {
    const { height, width, drops } = this.state
    let { characters, fontSize } = this.state

    const ctx = this.refs.canvas.getContext("2d")
    ctx.save()
    ctx.fillStyle = "rgba(0, 0, 0, 0.05)"
    ctx.fillRect(0, 0, width, height)

    ctx.fillStyle = "#00cc00"
    ctx.font = fontSize + "px arial"

    for (let i = 0; i < drops.length; i++) {
      const text = characters[Math.floor(Math.random() * characters.length)]
      ctx.fillText(text, i * fontSize, drops[i] * fontSize)

      if (drops[i] * fontSize > height && Math.random() > 0.935) drops[i] = 0
      drops[i]++
    }
    ctx.restore()
  }

  render() {
    const { width, height } = this.state

    return (
      <StyledMatrixAnimation>
        <canvas ref="canvas" width={width} height={height} />
      </StyledMatrixAnimation>
    )
  }
}

export default MatrixAnimation
