import { Header } from "semantic-ui-react"
import styled from "styled-components"

const StyledHeader = styled(Header)`
  @media (max-width: 450px) {
    display: ${({ target, as }) =>
      target === "short" || as === "h2" ? "block" : "none"};
  }

  font-size: ${({ as }) => (as === "h1" ? "3.8rem" : "2rem")};
  display: ${({ target, as }) =>
    target === "short" && as === "h1" ? "none" : "block"};
  text-align: center;
  font-weight: ${({ as }) => (as === "h1" ? "500" : "normal")};
  color: ${({ as, theme }) =>
    as === "h1" ? theme.mainTitleColor : theme.subTitleColor};
  font-family: ${({ as, theme }) =>
    as === "h1" ? theme.mainTitleFont : theme.subTitleFont};
  margin-bottom: ${({ as, theme }) =>
    as === "h1" ? "0rem" : "4rem"} !important;
  margin-top: ${({ as, theme }) =>
    as === "h1" ? "0rem" : "0.8rem"} !important;
`
export default StyledHeader
